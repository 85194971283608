

























































































































import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PrintInvoiceCustom extends Vue {
  totalData = 0;
  limit = 10;
  selectedRowKeys = [] as number[];
  dataSource = [] as { qty: number; key: number }[];
  query: RequestQueryParamsModel = {
    limit: 10,
    page: 0,
    search: "",
    sorts: "",
  };
  pagination = {
    current: 1,
    showSizeChanger: true,
    total: 0,
    pageSize: 10,
  };
  columnsTable = [
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: 200,
      scopedSlots: { customRender: "qty" },
      responsiveColInput: [
        {
          name: "qty",
          placeholder: "input Qty",
          style: "width: 100%",
          disabled: false,
        },
        {
          name: "description",
          placeholder: "input description",
          style: "width: 100%",
          disabled: false,
        },
        {
          name: "unitPrice",
          placeholder: "input Unit Price",
          style: "width: 100%",
          disabled: false,
        },
        {
          name: "total",
          placeholder: "input Total",
          style: "width: 100%",
          disabled: true,
        },
      ],
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
      scopedSlots: { customRender: "description" },
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 200,
      scopedSlots: { customRender: "unitPrice" },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: 200,
      scopedSlots: { customRender: "total" },
    },
  ];

  handleTableChange(event): void {
    if (event.pagination.current !== this.pagination.current) {
      this.query.page = event.pagination.current - 1;
      this.pagination.current = event.pagination.current;
    }

    if (event.pagination.pageSize !== this.query.limit) {
      this.query.limit = event.pagination.pageSize;
      this.query.page = 0;
      this.pagination.pageSize = event.pagination.pageSize;
    }
    this.$emit("change-page-limit", this.query);
  }

  onSelectChange(value) {
    this.selectedRowKeys = value;
  }

  handleBack() {
    console.log("back");
  }

  handleCancel() {
    console.log("cancel");
  }

  handlePrint() {
    console.log("print");
  }

  handleDeleteRow() {
    if (this.selectedRowKeys.length > 0) {
      this.$confirm({
        title: Messages.CONFIRM_DELETE_TITLE,
        content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
        onOk: () => {
          this.dataSource = this.dataSource.filter(data => {
            return !this.selectedRowKeys.includes(data.key);
          });
          this.totalData = this.dataSource.length;
        },
        onCancel() {
          return;
        },
      });
    } else {
      this.$notification.error({
        message: "Error",
        description: "Select at least one row to delete",
      });
    }
  }

  handleAddRow() {
    this.dataSource = [
      ...this.dataSource,
      { qty: 1, key: this.dataSource.length },
    ];
    this.totalData = this.dataSource.length;
  }

  handleInput(value, key, _objectColInput, objectColName, _onEvent): void {
    this.dataSource[key][objectColName] = value;
  }

  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
  }

  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
  }
}
