var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Print Invoice Custom" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-row",
                                { attrs: { gutter: [16, 16] } },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "a-space",
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: { click: _vm.handleAddRow }
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "plus" }
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("lbl_add_row")
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-button",
                                            {
                                              attrs: { type: "danger" },
                                              on: { click: _vm.handleDeleteRow }
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "delete" }
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("lbl_delete_row")
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 12, align: "end" } },
                                    [
                                      _c(
                                        "a-tag",
                                        {
                                          staticStyle: { "font-size": "13px" },
                                          attrs: { color: "grey" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lbl_total_found")
                                              ) +
                                              " : " +
                                              _vm._s(_vm.totalData) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c("TableCustom", {
                                        attrs: {
                                          onSelectChange: _vm.onSelectChange,
                                          selectedRowKeys: _vm.selectedRowKeys,
                                          dataSource: _vm.dataSource,
                                          columns: _vm.columnsTable,
                                          paginationcustom: false,
                                          defaultPagination: _vm.pagination,
                                          scroll: {
                                            x: "calc(700px + 50%)",
                                            y: 350
                                          },
                                          handleInput: _vm.handleInput
                                        },
                                        on: {
                                          "on-tablechange":
                                            _vm.handleTableChange
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "custom-footer",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "a-row",
                                                  {
                                                    staticClass:
                                                      "flex-column align-items-md-end",
                                                    attrs: { type: "flex" }
                                                  },
                                                  [
                                                    _c(
                                                      "a-col",
                                                      {
                                                        attrs: {
                                                          xs: { span: 24 },
                                                          lg: { span: 10 }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "table",
                                                          {
                                                            staticClass:
                                                              "footer-table",
                                                            attrs: {
                                                              "aria-describedby":
                                                                "footer table"
                                                            }
                                                          },
                                                          [
                                                            _c("tr", [
                                                              _c("th", {
                                                                attrs: {
                                                                  id:
                                                                    "just fix sonar"
                                                                }
                                                              }),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "lbl_sub_total"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c(
                                                                    "a-input",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          ""
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]),
                                                            _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "lbl_discount"
                                                                    )
                                                                  )
                                                                )
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [_c("a-input")],
                                                                1
                                                              )
                                                            ]),
                                                            _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "lbl_income_tax"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [_c("a-input")],
                                                                1
                                                              )
                                                            ]),
                                                            _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "lbl_tax"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c(
                                                                    "a-input",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          ""
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]),
                                                            _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "lbl_grand_total"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c(
                                                                    "a-input",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          ""
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 24, align: "end" } },
                                    [
                                      _c(
                                        "a-space",
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              staticStyle: {
                                                background: "#ffd966",
                                                color: "black",
                                                border: "#ffd966"
                                              },
                                              on: { click: _vm.handleBack }
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "left" }
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lbl_back")) +
                                                  " "
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-button",
                                            {
                                              attrs: { type: "danger" },
                                              on: { click: _vm.handleCancel }
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "close" }
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lbl_cancel")) +
                                                  " "
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: { click: _vm.handlePrint }
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "printer" }
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lbl_print")) +
                                                  " "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }